<template>
    <div class="card">
        <div class="card-header">
            {{ $t('crud.pickup_locations._create') }}
        </div>
        <div class="card-body">
            <pickup-locations-form-component @submit="create" :record="record"></pickup-locations-form-component>
        </div>
    </div>
</template>

<script>
    import {functions, config} from '../crud/mixin_config';
    import PickupLocationsFormComponent from "./Form";

    export default {
        name: 'pickup-locations-create-component',
        mixins: [functions],
        components: {PickupLocationsFormComponent},
        data() {
            return {
                crud: config('pickup_locations'),
                record: {
                    // Empty model.
                    payment_methods_ids: []
                }
            };
        }
    }
</script>
