<template>
    <form @submit.prevent="submit()">
        <b-form-group :label="$t('crud.name')">
            <b-form-input v-model="record.name" required></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.pickup_locations.description')">
            <b-form-textarea v-model="record.description" rows="4"></b-form-textarea>
        </b-form-group>
        <b-form-group :label="$t('crud.pickup_locations.street')">
            <b-form-input v-model="record.street"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.pickup_locations.city')">
            <b-form-input v-model="record.city"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.pickup_locations.postal_code')">
            <b-form-input v-model="record.postal_code"></b-form-input>
        </b-form-group>
      <b-form-group :label="$t('crud.pickup_locations.notification_emails')" :description="$t('crud.pickup_locations.notification_emails_description')">
        <b-form-input v-model="record.notification_emails"></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('crud.pickup_locations.list_send_time')" :description="$t('crud.pickup_locations.list_send_time_description')">
        <b-form-input v-model="record.list_send_time"></b-form-input>
      </b-form-group>
        <div class="row">
            <div class="col">
                <b-form-group :label="$t('crud.pickup_locations.geo_lat')">
                    <b-form-input v-model="record.geo_lat"></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group :label="$t('crud.pickup_locations.geo_lng')">
                    <b-form-input v-model="record.geo_lng"></b-form-input>
                </b-form-group>
            </div>
        </div>
        <hr />
      <b-form-group :label="$t('crud.pickup_locations.day_limit')">
        <b-form-input v-model="record.day_limit"></b-form-input>
      </b-form-group>
        <b-form-group :label="$t('crud.payment_methods._plural')">
            <b-form-select multiple v-model="record.payment_methods_ids" :options="payment_methods" text-field="name" value-field="id"></b-form-select>
        </b-form-group>
        <button class="btn btn-primary" type="submit">{{ $t('crud.save') }}</button>
    </form>
</template>

<script>
    export default {
        name: 'pickup-locations-form-component',
        props: ['record'],
        mounted() {
            this.$http.get('/payment-methods').then((response) => {
                this.payment_methods = response.data;
            })
        },
        data() {
            return {
                payment_method_ids: [],
                payment_methods: []
            }
        },
        methods: {
            submit() {
                this.$emit('submit');
            }
        }
    }
</script>
